$(document).on('turbo:load', function (e) {
  initializeZipCodeSelectors()
  // re initialize after ajax, using the js-form event
  $(document).on('js-dom-replaced', initializeZipCodeSelectors)
})

function initializeZipCodeSelectors () {
  $('.js-zip-code-selector').each(function () {
    $(this).find('input[type=text]').on('iso:autocomplete:selected', changeFieldData)
  })
  $('.js-zip-code-selector-reset').on('click', resetField)
}

function changeFieldData (e, args) {
  $(this).val(args.display)
}

function resetField (e, args) {
  $(this.parentElement).find('input').val('')
  $('.js-dateSelector').removeClass('index__dateSelector--show')
}
