import FormValidator from './formValidations.js'
import { initializeRadioItems } from './radio_item/radioItem.js'
import './checkbox/checkbox.js'
import { initializePhoneItems } from './phone/phone.js'
import { initializeDateRangePicker } from './date_range_picker/dateRangePicker.js'
import { initializeBarcode } from './barcode/barcode.js'
import { initializeMessageCard } from './message_card/messageCard.js'
import { clearValidationsFeedback, clearValidationsOnField } from './clearValidationFeedback.js'

function initializeForm () {
  if ($(this).attr('js-form-init')) {
    return
  } else {
    $(this).attr('js-form-init', true)
  }
  initializeRadioItems(this)
  initializePhoneItems(this)
  initializeDateRangePicker(this)
  initializeBarcode(this)
  initializeMessageCard(this)

  try {
    $(this).find('.select2.js-default-select').each(function () {
      const selectOptions = $(this).data()
      const element = this

      $(element).select2({
        width: '100%',
        minimumResultsForSearch: (selectOptions.showSearch ? 0 : -1),
        tags: selectOptions.withTags,
        multiple: selectOptions.multiple
      })

      // We need to dispatch change event here so we can add Stimulus.js
      // change listener to select
      $(element).on('select2:select', function () {
        const event = new Event('change', { bubbles: true })
        element.dispatchEvent(event)
      })
    })
  } catch (e) {
    // do nothing.
  }

  try {
    $(this).find('.js-tooltip').each(function () {
      new Tooltip(this, { title: this.dataset.tooltip, placement: 'right' }) // eslint-disable-line
    })
  } catch (e) {
    // do nothing.
  }

  $(this).find('.js-field-validation').each(tryAddingRequiredToLabel)

  $(this)
    .off('submit', handleFormSubmit)
    .on('submit', handleFormSubmit)

  $(this)
    .off('submit', showFunc)
    .on('submit', showFunc)

  $(this)
    .off('ajax:error', handleAjaxError)
    .on('ajax:error', handleAjaxError)

  $(this)
    .off('focus', 'input', handleFocusIn)
    .on('focus', 'input', handleFocusIn)

  $(this)
    .off('focusout', 'input', handleFocusOut)
    .on('focusout', 'input', handleFocusOut)

  $(this)
    .off('change', 'input.js-remote', handleJsInputRemote)
    .on('change', 'input.js-remote', handleJsInputRemote)

  $(this)
    .off('click', 'button.js-remote-reset', handleJsInputRemoteReset)
    .on('click', 'button.js-remote-reset', handleJsInputRemoteReset)
}

$(document)
  .off('turbo:load', doInitializations)
  .on('turbo:load', doInitializations)

$(document)
  .off('turbo:before-cache', preparePageToBeCached)
  .on('turbo:before-cache', preparePageToBeCached)

function showFunc () {
  console.log('submit event')
}

function preparePageToBeCached () {
  $(this).attr('js-form-init', false)
}

function doInitializations () {
  $('.js-form').each(initializeForm)
  $(document)
    .off('js-dom-replaced', doInitializations)
    .on('js-dom-replaced', doInitializations)
  $(document)
    .off('js-dom-replaced', scrollToError)
    .on('js-dom-replaced', scrollToError)
}

function tryAddingRequiredToLabel () {
  if (this.dataset.kind === 'presence') {
    const label = $(this).siblings('label').first()
    if (label.text().slice(-1) !== '*') {
      label.append(' *')
    }
  }
}

function handleJsInputRemote () {
  let form
  if (this !== undefined) {
    form = $(this).closest('form').get(0)
  } else {
    form = $('form')[0]
  }

  $(form).append('<input type="hidden" name="from_js_remote" value="1" />')
  form.requestSubmit()
  $(form).find('input[name=from_js_remote]').remove()
}

function handleJsInputRemoteReset (e) {
  $(this)
    .siblings('.form__field')
    .first()
    .find('.form__field__input input')
    .val('')
  const form = this.closest('form')
  form.fromAjaxField = true
  $(form).append('<input type="hidden" name="from_js_remote" value="1" />')
  $(form).submit()
  $(form).find('input[name=from_js_remote]').remove()
  return false
}

// Enables the submit button
function enableSubmitButton () {
  $('.js-form input[type="submit"], .js-form button[type="submit"]').each(function () {
    if (this.disabled === true) {
      this.removeAttribute('disabled')
    }
    removeSubmitDisabledClass(this)
  })
}

function disableSubmitButton (form) {
  $(form).find('input[type="submit"], button[type="submit"]').each(function () {
    if (this.disabled === false) {
      this.disabled = true
    }
    addSubmitDisabledClass(this)
  })
}

// Disables all inpus on AJAX request.
function disableInputsForAJAX (form) {
  $(form).find('input, .form__field__input').each(function () {
    if (this.type === 'hidden') {
      return true // skip to next
    }
    if ((typeof this.closest !== 'undefined') && this.closest('.js-autocompleteField__wrapper')) {
      return true // skip if inside autocomplete
    }
    if (this.disabled === false) {
      this.disabled = true
      if (!this.classList.contains('js-ajax-disabled')) {
        this.classList.add('js-ajax-disabled')
      }
    }
    addSubmitDisabledClass(this)
  })
}

const submitDisabled = 'form__submitDisabled'

function addSubmitDisabledClass (item) {
  if (!item.classList.contains(submitDisabled)) {
    item.classList.add(submitDisabled)
  }
}

function removeSubmitDisabledClass (item) {
  if (item.classList.contains(submitDisabled)) {
    item.classList.remove(submitDisabled)
  }
}

function enableInputsForAJAX () {
  $('.js-ajax-disabled').removeClass('js-ajax-disabled').each(function () {
    this.disabled = false
  })
}

function handleFocusIn (e) {
  const validationHook = $(e.target).closest('.js-form-validation')
  if (validationHook.length > 0) {
    clearValidationsOnField(validationHook)
  }
}

function handleFocusOut (e) {
  const validationHook = $(e.target).closest('.js-form-validation')
  if (validationHook.length > 0) {
    clearValidationsOnField(validationHook)
    new FormValidator({
      validFeedbackFn: displayValidFeedback,
      invalidFeedbackFn: displayInvalidFeedback
    }).isFieldValid(validationHook)
  }
}

function submitComesFromAjaxField (form) {
  if ($(form).find('input[name=from_js_remote]').length > 0) {
    return true
  }
  if (form.fromAjaxField === true) {
    return true
  }
  return false
}

function skipValidations (form) {
  return $(form).find('input[name=no_validate]').length > 0
}

function handleAjaxError (e, args) {
  console.log(e.detail) // eslint-disable-line
  enableInputsForAJAX(e.target)
  enableSubmitButton()
}

function handleFormSubmit (e) {
  disableSubmitButton(e.target)
  const ret = internalSubmitHandler(e)
  enableInputsForAJAX(e.target)
  return ret
}

function internalSubmitHandler (e) {
  const form = e.target
  const jqForm = $(form)
  clearValidationsFeedback(jqForm)
  const formValidator = new FormValidator({
    form: jqForm,
    validFeedbackFn: displayValidFeedback,
    invalidFeedbackFn: displayInvalidFeedback
  })
  if (submitComesFromAjaxField(form) || skipValidations(form) || formValidator.isFormValid()) {
    disableInputsForAJAX(form)
    if (jqForm.find('input[name=js-submitting]').length > 0) {
      return false
    }

    if (jqForm.find('input[name=js-no-ajax-submitting]').length > 0) {
      return true
    }
    if (jqForm.data().remote !== true) {
      jqForm.append('<input type="hidden" name="js-no-ajax-submitting" value="" />')
      jqForm.submit()
      return false
    }
    return true
  } else {
    scrollToError(jqForm)
    enableSubmitButton()
    return false
  }
}

function displayInvalidFeedback (field, errorMessage) {
  if (field.closest('.js-form-one-line-fields').length > 0) {
    field
      .closest('form')
      .append(`<div class="form__field__errors__line">${errorMessage}</div>`)
  } else {
    field
      .append(`<div class="form__field__errors__line">${errorMessage}</div>`)
  }
  field.removeClass('form__field--valid')
  if (!field.hasClass('form__field--invalid')) {
    field.addClass('form__field--invalid')
  }
}

function displayValidFeedback (field) {
  field
    .find('.form__field__errors_line')
    .remove()
  field.removeClass('form__field--invalid')
  if (!field.hasClass('form__field--valid')) {
    field.addClass('form__field--valid')
  }
}

function scrollToError (form) {
  if ($('.form__field--invalid').length === 0) {
    scrollToDeclinedPayment()
    return
  }
  $('body, html').animate({
    scrollTop: $('.form__field--invalid').offset().top - 50
  }, 500)
}

function scrollToDeclinedPayment (form) {
  if ($('.payment__error').length === 0) {
    return
  }
  $('body,html').animate({
    scrollTop: $('.payment__error').offset().top - 150
  }, 500)
}
