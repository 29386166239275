export function initializePhoneItems (form) {
  try {
    $(form).find('.select2.js-country')
      .select2({
        templateResult: countryTemplate,
        templateSelection: countryTemplate,
        minimumResultsForSearch: Infinity
      })
      .off('select2:select', countrySelected)
      .on('select2:select', countrySelected)
      .each(countrySelected) // initialize prefix to pre-selected value
  } catch (e) {
    // do nothing.
  }
}

function countrySelected () {
  const selectedCode = this.options[this.selectedIndex].dataset.code
  this.parentElement.querySelector('.phone__prefix').value = `+${selectedCode}`
}

function countryTemplate (country) {
  if (!country.id) { return country.text }
  const $country = $(`
    <div class="select__phone__country">
      <img src="${country.element.dataset.svg}" class="flag" />
      <span class="text">${country.text}</span>
    </div>
  `)
  return $country
}
