function radioItemClicked (e) {
  const visualItem = $(this).find('.js-radio-item').first().get()[0]
  const input = $(this).find('.js-radio-value-hide').get()[0]
  if (input.disabled) {
    return
  }
  $(this).closest('.js-radio-options').find('.js-radio-item')
    .each((index, item) => makeInactive(item))
  makeActive(visualItem, input)
  e.preventDefault()
  e.stopPropagation()
  return false // stops event bubling
}

function makeInactive (item) {
  item.classList.remove('radioItem__wrapper--selected')
}

function makeActive (visualItem, input) {
  visualItem.classList.add('radioItem__wrapper--selected')
  if (!input.checked) {
    input.checked = true
    $(input).change()
  }
  $(document).trigger('js-form:radioItemActive', [input])
}

export function initializeRadioItems (form) {
  $(form).find('.js-radio-options label')
    .off('click', radioItemClicked)
    .on('click', radioItemClicked)

  $(form).find('.js-radio-group').each(function () {
    if (this.dataset.preselect !== 'true') {
      return true
    }
    if ($(this).find('input[checked=checked]').length > 0) {
      // if there is already an item selected, do nothing:
      return true
    }

    const firstAvailableOption = $(this).find('.js-radio-options label input:not(:disabled)').first().parent()
    firstAvailableOption.click()
  })
}
