import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['select']
  }

  selectTargetConnected (element) {
    if (this.choicesInstance) {
      return
    }

    // By default choices.js sorts the options alphabetically, so we keep that behavior unless explicitly told not to
    const shouldSort = this.element.dataset['shouldSort'] != 'false'
    import('choices.js')
      .then(({ default: Choices }) => {
        this.choicesInstance = new Choices(
          element,
          {
            itemSelectText: '',
            position: 'bottom',
            searchResultLimit: 20,
            shouldSort: shouldSort,
            callbackOnInit: () => {
              // Add a data attribute to the select element to indicate that the choices instance has been initialized
              this.element.dataset.choicesInitialized = true
            }
          }
        )

        if (this.element.dataset.remoteSearchPath) {
          this.element.addEventListener('search', async (e) => {
            const query = e.detail.value
            let searchPath = this.element.dataset.remoteSearchPath
            if (searchPath.includes('?')) {
              searchPath += '&q=' + query
            } else {
              searchPath += '?q=' + query
            }
            const data = await fetch(searchPath).then(res => {
              return res.json()
            })

            this.choicesInstance.setChoices(data, 'id', 'name', true)
          })
        }
      })
      .catch((_error) => 'An error occurred while loading Choices')
  }
}
