// NOTE: do not modify this file manually. Use components_base:asset_builder:update_auto_importers task from main app

import '@hotwired/turbo-rails'
import { stimulusApp } from '../../../internal/stimulus_app.js'
let stimulus = stimulusApp();
import choices_select_input from '../ui/components/form/choices_select_input_controller.js'
stimulus.register('choices-select-input', choices_select_input)
import '../ui/components/autocomplete_field/autocompleteField.js';
import '../ui/components/button/button.js';
import '../ui/components/code_example/codeExample.js';
import '../ui/components/form/barcode/barcode.js';
import '../ui/components/form/checkbox/checkbox.js';
import '../ui/components/form/clearValidationFeedback.js';
import '../ui/components/form/date_range_picker/dateRangePicker.js';
import '../ui/components/form/form.js';
import '../ui/components/form/formValidations.js';
import '../ui/components/form/message_card/messageCard.js';
import '../ui/components/form/phone/phone.js';
import '../ui/components/form/radio_item/radioItem.js';
import '../ui/components/image_upload/imageUpload.js';
import '../ui/components/modal/modal.js';
import '../ui/components/page_filters/pageFilters.js';
import '../ui/components/pagination/pagination.js';
import '../ui/components/zip_code_selector/zipCodeSelector.js';