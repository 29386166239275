$(document).ready(function (e) {
  $('.js-pagination').each(initializePaginator)
})

function initializePaginator () {
  const $this = $(this)
  const prev = $this.find('.previous_page')
  const next = $this.find('.next_page')

  // remove arrows that might come from front translation:
  prev.text(prev.text().replace('←', ''))
  prev.prepend(`
    <div class='oi oi-chevron-left'></div>
  `)

  next.text(next.text().replace('→', ''))
  next.append(`
    <div class='oi oi-chevron-right'></div>
  `)
}
