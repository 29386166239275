$(function () {
  $('.js-slide-toggle').on('click', (e) => {
    e.preventDefault()
    const clicked = $(e.target)
    const content = clicked.parent().siblings('.js-slide-hidden')
    content.slideToggle('fast', (x) => {
      if (content.css('display') === 'none') {
        clicked.html('(click to show)')
      } else {
        clicked.html('(click to hide)')
      }
    })
  })
})
