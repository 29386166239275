// Date range picker field, based on http://www.daterangepicker.com
import DateRangePicker from 'daterangepicker' // eslint-disable-line no-unused-vars
import moment from 'moment-timezone-with-data-2010-2020'

export function initializeDateRangePicker (form) {
  $(form).find('.js-daterangepicker').each(function (e) {
    if ($(this).data('daterangepicker')) {
      $(this).data('daterangepicker').remove()
    }
    const options = defaultOptions()

    // date parsing:
    const values = this.dataset.value.split('__')
    if (values[0] !== '') {
      options.startDate = moment(values[0])

      if (this.dataset.ignoreTimeZone === 'true') {
        options.startDate = moment(values[0])
          .add(moment.parseZone(values[0]).utcOffset(), 'm')
      } else {
        options.startDate = moment(values[0])
      }
    }

    if (values[1] !== '') {
      if (this.dataset.ignoreTimeZone === 'true') {
        options.endDate = moment(values[1])
          .add(moment.parseZone(values[1]).utcOffset(), 'm')
      } else {
        options.endDate = moment(values[1])
      }
    }

    // include a time picker
    if (this.dataset.includeTime === 'true') {
      options.timePicker = true
      options.locale.format = 'HH:mm ddd Do MMM YY'
    }

    // single vs range: (by default is range)
    if (this.dataset.single === 'true') {
      options.singleDatePicker = true
      delete options.ranges
    }

    $(this).data('daterangepicker', new DateRangePicker($(this), options, onFieldUpdated))
  })
}

function onFieldUpdated (start, end, _label) {
  const element = $(this.element)
  let valueElement

  // TODO: This breaks if the field has an error..
  if (element.parent().attr('class') === 'field_with_errors') {
    valueElement = element.parent().siblings('input[type=hidden]')
  } else {
    valueElement = element.next()
  }

  console.log(start) // eslint-disable-line

  if (element.data('includeTime')) {
    if (element.data('ignoreTimeZone')) {
      // Strip any time zone information from the input
      return valueElement.val(`${start.format('YYYY-MM-DD[T]HH:mm')}__${end.format('YYYY-MM-DD[T]HH:mm')}`)
    }
    valueElement.val(`${start.toISOString()}__${end.toISOString()}`)
  } else {
    valueElement.val(`${start.local().format('YYYY-MM-DD')}__${end.local().format('YYYY-MM-DD')}`)
  }
  valueElement.trigger('change')
}

function defaultOptions () {
  return {
    alwaysShowCalendars: true,
    linkedCalendars: false,
    showDropdowns: true,
    timePicker: false,
    timePicker24Hour: true,
    timePickerIncrement: 30,
    buttonClasses: 'button__wrapper',
    applyButtonClasses: '',
    cancelClass: 'button__wrapper--secondary',
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    minYear: 2015,
    locale: {
      format: 'ddd Do MMM YY',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Custom',
      weekLabel: 'W',
      daysOfWeek: [
        'Su',
        'Mo',
        'Tu',
        'We',
        'Th',
        'Fr',
        'Sa'
      ],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      firstDay: 1
    }
  }
}
