function initializePageFilters () {
  const hiddenClassName = 'pageFilters__hidden'
  $('.js-page-filter-switch').click(function () {
    if ($('.js-page-filters-compact').is(':visible')) {
      $('.js-page-filters-compact').addClass(hiddenClassName)
      $('.js-page-filters-form').removeClass(hiddenClassName)
    } else {
      $('.js-page-filters-compact').removeClass(hiddenClassName)
      $('.js-page-filters-form').addClass(hiddenClassName)
    }
  })
}

$(document)
  .off('turbo:load', initializePageFilters)
  .on('turbo:load', initializePageFilters)
