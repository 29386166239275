import throttle from 'lodash.throttle'

const componentJsHook = '.js-autocompleteField__wrapper'
let currentRequest

$(document).on('turbo:load', function (e) {
  $(document).on('input', `${componentJsHook} input`, (e) => {
    e.preventDefault()
    const requestValue = e.currentTarget.value
    if (requestValue.length < 1) {
      hideResultsList()
      // Do not make ajax call unless there is input in field
      return
    }
    const component = $(e.currentTarget).closest(componentJsHook)
    const requestUrl = component.data('search-url')
    throttleRequest(requestValue, requestUrl, component)
  })
  // also fire when any child of .js-result is clicked:
  const result = `${componentJsHook} .js-result`
  $(document).on('click', `${result}`, resultClicked)

  $(document).on('click', 'body', hideResultsList)
  $('.js-mobile-sidebar-menu-close').on('click', hideResultsList)
})

function resultClicked (e) {
  if ($(e.originalEvent.target).parent().hasClass('autocompleteField__result--disabled') || $(e.originalEvent.target).hasClass('autocompleteField__result--disabled')) {
    return
  }

  e.preventDefault()

  if (e.currentTarget.isConnected === false) {
    e.preventDefault()
    e.stopImmediatePropagation()
    return false
  }
  let resultNode = $(e.currentTarget)
  if (!resultNode.data('on-select')) {
    resultNode = $(e.currentTarget).closest('.js-result')
  }
  const component = $(e.currentTarget).closest(componentJsHook)

  const selectedData = resultNode.data('on-select')
  const hiddenSelectedData = resultNode.data('on-hidden-select')

  const input = component.find('input')
  const hiddenInput = component.find('input[type="hidden"]')

  input.val(selectedData)
  input.trigger('iso:autocomplete:selected', resultNode.data())

  if (hiddenInput && hiddenSelectedData) {
    hiddenInput.val(hiddenSelectedData)
  }

  hideResultsList()
  const nextUrl = component.data('next-url')
  if (nextUrl !== undefined && nextUrl !== '') {
    const form = component.closest('form')[0]
    const oldUrl = form.action
    form.action = nextUrl
    $(form).append('<input type="hidden" name="from_autocomplete" value="1" />')
    $(form).append('<input type="hidden" name="from_js_remote" value="1" />')
    form.requestSubmit()
    if ($(form).data('remote')) {
      form.action = oldUrl
    }
  }
  return false
}

function hideResultsList (e) {
  if (currentRequest) {
    currentRequest.abort()
  }

  // when called from ResultClicked, the event is null (and there is no need to
  // check if it should be kept open):
  if (e && e.target && (typeof e.target.closest !== 'undefined') && e.target.closest('.js-autocompleteField__wrapper')) {
    return
  }
  $('.js-results-container').html(null)

  if (e !== undefined) {
    $(e.target).find(componentJsHook).removeClass('autocompleteField__wrapper--frontIndex--filled')
    $(e.target).find('.js-input-wrapper').find('.autocompleteField__inputFilled').removeClass('autocompleteField__inputFilled autocompleteField__inputFilled2').val('')
  }
}

function autocompleteRequest (requestValue, requestUrl, component) {
  if (requestUrl === undefined) {
    return
  }
  $(document).trigger('js-autocomplete:started')
  currentRequest = $.ajax({
    data: { query: requestValue },
    type: 'post',
    dataType: 'html',
    cache: false,
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    url: requestUrl,
    success: (e) => {
      if ($('.autocompleteField__wrapper--frontIndex').length > 0) {
        component.closest('.autocompleteField__wrapper--frontIndex').addClass('autocompleteField__wrapper--frontIndex--filled')
      }
      if ($('.autocompleteField__wrapper--formShow').length > 0) {
        component.closest('.autocompleteField__wrapper--formShow').addClass('autocompleteField__wrapper--formShow--filled')
      }
      // Adding second class for specificity instead of using !important - because of zipCodeSelector variant,
      // we need 2 classes to override styling of .frontInput
      component.find('input[type=text]').addClass('autocompleteField__inputFilled autocompleteField__inputFilled2')
      component.find('.js-results-container').html(e)
    }
  })
}

const throttleRequest = throttle(
  autocompleteRequest,
  500
)
