$(document).on('turbo:load', doInitializations)
$(document).on('js-form-button:init', doInitializations)

function doInitializations () {
  $('.button__wrapper[type=submit]').on('click', onClickedSubmitButton)
}

function onClickedSubmitButton (e) {
  $('#js_submit_name').remove()
  $(e.target).closest('form').append(`
    <input type="hidden"
           id="js_submit_name"
           name="js_submit_name"
           value="${e.currentTarget.name}" />
  `)
}
