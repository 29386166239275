const knownValidations = new Map([
  ['presence', value => value.toString().trim().length > 0],
  ['acceptance', value => value], // checks it is true
  ['format', (value, dataset) => {
    if (dataset.allowBlank === 'true' && (value === undefined || value === '')) {
      return true
    }
    return new RegExp(dataset.source, dataset.options).test(value)
  }]
])

export default class FormValidator {
  constructor (args) {
    this.form = args.form
    this.invalidFeedbackFn = args.invalidFeedbackFn
    this.validFeedbackFn = args.validFeedbackFn
  }

  isFormValid () {
    console.log('Checking form validation') // eslint-disable-line
    let valid = true
    let field
    let shouldCheck
    let currentValidationResult
    this.form.find('.js-form-validation').get().forEach(domField => {
      field = $(domField)
      console.log(field)
      shouldCheck = !field.attr('disabled') && !field.attr('readonly')
      if (shouldCheck) {
        currentValidationResult = this.isFieldValid(field)
        valid = valid && currentValidationResult
      }
    })
    return valid // form is valid!
  }

  isFieldValid (field) {
    let inputField
    // select is after input to avoid setting choices_select search input as inputField
    ['input', 'select', 'textarea'].forEach(function (val) {
      if (field.find(val).last()[0] !== undefined) {
        inputField = $(field.find(val).last()[0])
      }
    })

    const value = this._getFieldValue(field, inputField)
    const failedValidation = this._getFieldValidators(field)
      .get() // remove jquery wrapper to do a JS Array find:
      .find(validator => {
        return this._doValidation(field, value, validator.dataset.kind, inputField, validator.dataset) === false
      })
    if (failedValidation) {
      console.log('form validation failed ', inputField) // eslint-disable-line
      this.invalidFeedbackFn(field, failedValidation.dataset.msg)
      return false
    }
    this.validFeedbackFn(field)
    return true
  }

  _getFieldValue (field, inputField) {
    let inputValue = inputField.val() || ''

    if (inputField.prop('type') === 'checkbox') {
      inputValue = inputField.prop('checked')
    }
    // some fields like phone number or credit card add spaces.
    // remove them here before validating if desired:
    if (field.data('js-remove-spaces')) {
      return inputValue.replace(/ /g, '') // remove spaces.
    } else {
      return inputValue
    }
  }

  _getFieldValidators (field) {
    return field.find('.js-field-validation')
  }

  _doValidation (field, fieldValue, kind, inputField, options) {
    if (inputField.attr('disabled') || inputField.attr('readonly')) {
      return true
    }
    const validationFn = knownValidations.get(kind)
    if (validationFn) {
      return validationFn(fieldValue, options)
    }
    throw String(`validation kind '${kind}' is unknown => field id: ${inputField.attr('id')}, name: ${inputField.attr('name')}`)
  }
}
