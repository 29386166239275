function countCharacters (e) {
  const charCounter = $(e.currentTarget).parents().next('.js-characterCount')
  const maxLength = $(e.currentTarget).attr('maxLength')
  const messageLength = $(e.currentTarget).val().length
  charCounter.text(messageLength + '/' + maxLength)
}

function preventBreakLines (e) {
  if (e.keyCode === 13) {
    e.preventDefault()
    return false
  }
}

function removeBreakLinesFromPastedText (e) {
  // setTimeout to wait for having the text pasted in the target
  setTimeout(function () {
    const pastedText = $(e.currentTarget).val()
    const pastedTextWithoutLineBreaks = pastedText.replace(/[\r\n]+/g, ' ')
    $(e.currentTarget).val(pastedTextWithoutLineBreaks)
  }, 0)
}

export function initializeMessageCard (form) {
  const messageCard = $(form).find('.js-messageCard')
  if (messageCard.length === 0) {
    return
  }

  messageCard
    .off('keypress', preventBreakLines)
    .on('keypress', preventBreakLines)
  messageCard
    .off('paste', removeBreakLinesFromPastedText)
    .on('paste', removeBreakLinesFromPastedText)

  if ($(form).find('.js-characterCount').length > 0) {
    messageCard
      .off('keyup', countCharacters)
      .on('keyup', countCharacters)
  }
}
