const barcodeInputClass = 'js-barcode-input'

export function initializeBarcode (form) {
  const barcodeInput = document.querySelectorAll(`form input.${barcodeInputClass}`)

  // NOTE: In certain cases (mainly for devices using Turoblinks) the listener
  //       remains through page views, which is fine usually, but sometimes
  //       we want to change the submit on scan behaviour, so we need to
  //       reinitialize the listeners.
  GenericBarcodeScanner.unlisten()
  ScannerEventHandler.unlisten()

  if (barcodeInput.length === 1) {
    const noSubmitOnScan = barcodeInput[0].dataset.noSubmitOnScan

    GenericBarcodeScanner.resetInput()

    if (!GenericBarcodeScanner.listening()) {
      GenericBarcodeScanner.listen()
    }

    if (!ScannerEventHandler.listening()) {
      ScannerEventHandler.listen(noSubmitOnScan)
    }

    return
  }

  if (barcodeInput.length > 1) {
    console.error('more than one barcode input') // eslint-disable-line no-console
  }

  if (GenericBarcodeScanner.listening()) {
    GenericBarcodeScanner.unlisten()
  }

  if (ScannerEventHandler.listening()) {
    ScannerEventHandler.unlisten()
  }
}

const ScannerEventHandler = (function () {
  function ScannerEventHandler () {}

  ScannerEventHandler.listen = function (noSubmitOnScan) {
    console.log('Listening for barcode event') // eslint-disable-line no-console
    if (noSubmitOnScan !== 'true') {
      document.addEventListener('scannerInput', submitScannerInput)
    }
    window.scan_event_handler_listening = true
  }

  ScannerEventHandler.listening = function () {
    return window.scan_event_handler_listening === true
  }

  ScannerEventHandler.unlisten = function () {
    document.removeEventListener('scannerInput', submitScannerInput)
    window.scan_event_handler_listening = false
  }

  const submitScannerInput = function (event) {
    const barcode = event.detail.barcode
    const input = document.querySelector(`form input.${barcodeInputClass}`)
    input.value = barcode
    if (input.form.dataset['confirm'] && !window.confirm(input.form.dataset['confirm'])) {
      return;
    }

    ScannerEventHandler.unlisten()
    input.form.submit()
  }

  return ScannerEventHandler
})()

const GenericBarcodeScanner = (function () {
  function GenericBarcodeScanner () {}
  let chars = []

  GenericBarcodeScanner.listen = function () {
    document.addEventListener('keypress', processKeypress)
    window.barcode_scanner_listening = true
  }

  GenericBarcodeScanner.listening = function () {
    return window.barcode_scanner_listening === true
  }

  GenericBarcodeScanner.unlisten = function () {
    document.removeEventListener('keypress', processKeypress)
    window.barcode_scanner_listening = false
  }

  GenericBarcodeScanner.resetInput = function () {
    chars = []
  }

  const scan = function (barcode) {
    console.log('Caught barcode ' + barcode) // eslint-disable-line no-console
    const event = new CustomEvent('scannerInput', { detail: { barcode: barcode } })
    document.dispatchEvent(event)
    chars = []
  }

  const processKeypress = function (e) {
    // Don't process keypresses from input fields
    if (e.target.tagName === 'INPUT') { return }

    let barcode
    if (!([10, 13].includes(e.which))) {
      chars.push(String.fromCharCode(e.which))
      console.log('Barcode chars: ' + chars) // eslint-disable-line no-console
    } else {
      barcode = chars.join('').replace(/§/g, '-').replace(/(\r\n|\n|\r)/gm, '')
      return scan(barcode)
    }
  }

  return GenericBarcodeScanner
})()
