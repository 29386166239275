$(document).on('turbo:load', detectEdge)

function detectEdge () {
  if ($('.checkbox__checkboxInput').length === 0) {
    return
  }
  const ua = window.navigator.userAgent
  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    $('.checkbox__checkboxInput').addClass('checkbox__checkboxInput--edge')
    $('.checkbox__checkboxIndicator').hide()
  }
}
