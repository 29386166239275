import Croppie from 'croppie'

window.Croppie = Croppie

$(document).on('turbo:load', initialize)
$(document).on('js-dom-replaced', initialize)

let $uploadCrop

function initialize () {
  if ($('.js-image-upload').length === 0) {
    return
  }

  if (!$('.js-selected-image-wrapper').hasClass('js-selected-image-preview')) {
    $('.js-selected-image-wrapper').addClass('js-selected-image-wrapper--hide')
  }
  $uploadCrop = $('.js-image-upload .js-croppie')
    .croppie({
      viewport: {
        width: 150,
        height: 150
      },
      mouseWheelZoom: false,
      enableExif: false,
      enableOrientation: true,
      boundary: {
        width: 230,
        height: 230
      },
      class: 'imageUpload__croppie'
    })
    .on('update.croppie', function (ev, cropData) {
      const croppieElement = this
      $uploadCrop.croppie('result', {
        type: 'base64',
        // size: 'original',
        size: {
          width: 600,
          height: 600
        },
        format: 'jpeg'
      }).then(function (resp) {
        const y = $(croppieElement).siblings('#js-img-upload-hidden')
        y.val(resp)
      })
    })
    .each(function () {
      $(this).closest('.js-image-upload').find('.js-upload-error').hide()
      const previewURL = $(this).siblings('#js-img-upload-prev').val()
      if (previewURL.length > 0) {
        $(this).croppie('bind', { url: previewURL, zoom: 0 })
        $('.js-selected-image-wrapper').removeClass('js-selected-image-wrapper--hide')
      } else {
        // if there is no image, hide controls:
        remove({ data: { croppieElement: $(this) } })
      }
      const buttons = $(this).siblings('.js-croppie-buttons')
      buttons.find('.js-rotate-left').on('click', { croppieElement: $(this) }, rotateLeft)
      buttons.find('.js-rotate-right').on('click', { croppieElement: $(this) }, rotateRight)
      buttons.find('.js-remove').on('click', { croppieElement: $(this) }, remove)
    })

  $('.js-image-upload .js-image-upload-get-file').on('change', function () { readFile(this) })
  $('.js-image-upload').on('click', function (ev) {
    // When clicking the container, assume the image has potentially been modified:
    $uploadCrop.siblings('#js-img-upload-modified').val('true')
  })
  $('.js-upload-btn').on('click', function (ev) {
    ev.preventDefault()
    $(this).parent().find('.js-image-upload-get-file').click()
  })
}

function readFile (input) {
  $(input).siblings('.js-upload-error').html('').hide()
  if (input.files && input.files[0]) {
    if (input.files[0].size > 4000000) {
      $(input).siblings('.js-upload-error').html('4mb max.').show()
      input.files = null
      return
    }
    const reader = new FileReader()
    $('.js-selected-image-wrapper').removeClass('js-selected-image-wrapper--hide')

    reader.onload = function (e) {
      $('.upload-demo').addClass('ready')
      if ($uploadCrop.length > 0) {
        $uploadCrop.croppie('bind', {
          url: e.target.result,
          zoom: 0
        }).then(function () {
          $('.js-selected-image-wrapper').css('visibility', 'inherit')
        })
      } else {
        const $previewDiv = $(input).parents('.js-image-upload').find('.js-img-upload-prev')
        $previewDiv.attr('src', e.target.result)
        // for ix_image_tag
        $previewDiv.attr('srcset', e.target.result)
      }
    }

    reader.readAsDataURL(input.files[0])
  } else {
    // swal("Sorry - your browser doesn't support the FileReader API")
  }
}

function rotateLeft (event) {
  const croppieElement = event.data.croppieElement
  croppieElement.siblings('#js-img-upload-modified').val('true')
  croppieElement.croppie('rotate', 90)
}

function rotateRight (event) {
  const croppieElement = event.data.croppieElement
  croppieElement.siblings('#js-img-upload-modified').val('true')
  croppieElement.croppie('rotate', -90)
}

function remove (event) {
  const croppieElement = event.data.croppieElement
  const y = croppieElement.siblings('#js-img-upload-hidden')
  y.val('')
  croppieElement.siblings('#js-img-upload-modified').val('true')
  const fileInput = croppieElement.closest('.js-image-upload').find('.js-image-upload-get-file')[0]
  fileInput.files = null
  fileInput.value = ''
  $('.js-selected-image-wrapper').css('visibility', 'hidden')
  $('.js-selected-image-wrapper').addClass('js-selected-image-wrapper--hide')
}
