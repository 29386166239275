export function clearValidationsFeedback (form) {
  form.find('.js-form-validation').get().forEach(clearValidationsOnField)
}

export function clearValidationsOnField (domField) {
  $('.js-flash').remove()
  const field = $(domField)
  if (field.closest('.js-form-one-line-fields').length > 0) {
    field
      .closest('form')
      .find('.form__field__errors__line')
      .remove()
  } else {
    field
      .find('.form__field__errors__line')
      .remove()
  }
  field.removeClass('form__field--invalid')
  field.removeClass('form__field--valid')
}
